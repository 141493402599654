/* components::product
------------------------------------------------------------------------------------------------------------------- */
.product {
  display: flex;
  flex-direction: column;
  position: relative;

  &__img {
    display: grid;
    padding: 10px;
    background: $clr-ntrl-min;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    border: 1px solid $clr-base-ltst;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .btn {
      justify-content: center;
      position: absolute;
      border-radius: 4px;
      left: 10px;
      bottom: 10px;
      width: calc(100% - 20px);
      text-align: center;
      padding: 10px;
      transform: translateY(150%);

      @include transition (transform 0.325s ease-in-out);

      @include responsive-down(tablet-extra-large) {
        transform: translateY(0);
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 6px 10px;

        @include transition(transform 0.325s ease-in-out);
      }

      span {
        font-sizE: 14px;
        line-height: 18px;
      }

      &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;

        &:hover {
          &::before {
            display: none;
          }
        }
      }
    }
  }

  .tag-product {
    border-radius: 9px;
    padding: 2px 5px;
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 11px;

    span {
      line-height: 1;
    }

    &--success {
      background-color: #c0e5a6;

      span {
        color: #6B805c;
      }
    }
  }

  &__title {
    font-size: 16px;
    color: $clr-base;
    margin: 16px 0 4px;
    height: 42px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @include responsive-down(mobile-large) {
      font-size: 15px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &__description {
    height: 32px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: $clr-base-lt;
    font-size: 12px;
    margin: 0 0 15px;
    line-height: 1.3;
  }

  .content-prices {
    padding: 0 0 15px;

    .price {
      font-size: 17px;
      margin: 0;
      display: inline-flex;
      align-items: center;
      font-weight: 500;

      .iva {
        font-size: 14px;
      }

      &--with-tax {
        display: flex;
        margin-top: 0;
        font-size: 14px;
        font-weight: 500;
        color: $clr-base-lt;
      }

      &--discount {
        color: $clr-base-ltr;

        span:nth-child(2) {
          font-weight: 500;
          text-decoration: line-through;
          order: 3;
        }

        .discount {
          color: $clr-danger;
          font-weight: 500;
          margin-right: 5px;
        }

        .iva {
          color: $clr-danger;
          margin-right: 5px;
        }
      }
    }
  }

  &:hover {
    @include responsive-up(tablet-extra-large) {
      .product__img {
        .btn {
          transform: translateY(0);

          @include transition(transform 0.325s ease-in-out);
        }
      }
    }
  }
}
