/* components:: partials:: header
--------------------------------------------------------------------------------------------------------------------- */
.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
  background-color: $clr-ntrl-min;

  @include transition(all 0.5s ease-in-out);

  @include responsive-down(tablet) {
    padding-bottom: 56px;
  }

  &__top {
    padding: 6px 0;
    background-color: $clr-base;
    position: relative;
    z-index: 5;
    transform: translateY(0);
    height: auto;

    @include transition(all 0.325s ease-in-out);

    .tns-visually-hidden {
      display: none;
    }

    &-container {
      display: flex;
      justify-content: space-around;
      align-items: center;

      @include responsive-up(tablet) {
        display: flex;
        justify-content: space-around;
        align-items: center;
      }

      * {
        color: $clr-base-ltst;
      }
    }
  }

  &__top-item {
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 12px;

    svg {
      width: 15px;
      height: auto;
      margin-right: 5px;
      fill: $clr-ntrl-min;
    }

    @include responsive-down(tablet-extra-large) {
      display: none !important;

      &:nth-child(1) {
        display: inline-flex !important;
      }

      &.tns-item {
        display: inline-flex !important;
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 5;
    background-color: $clr-ntrl-min;
  }

  &__corporate {
    display: flex;
    align-items: center;
    padding: 10px 0;

    .burger-menu {
      margin-right: 15px;

      &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 40px;
        height: 40px;
        border-radius: 100px;
        background-color: $clr-base-ltst;
        cursor: pointer;

        @include transition(background-color 0.325s ease-in-out);

        span {
          display: block;
          width: 20px;
          height: 1px;
          margin: 3px auto;
          background-color: $clr-base;

          @include transition (all 0.5s cubic-bezier(0.1, 0.82, 0.76, 0.965));
        }

        &--active {
          animation: burgerMenuIconAnimation 0.6s forwards;

          span {
            @include transition(all 0.325s ease-in-out);

            &:nth-child(1) {
              transform: translateY(3px) rotate(45deg);
            }

            &:nth-child(2) {
              transform: translateY(-4px) rotate(-45deg);
            }
          }
        }

        &:hover {
          @include responsive-up(tablet-extra-large) {
            background-color: $clr-primary;

            @include transition(background-color 0.325s ease-in-out);

            span {
              background-color: $clr-ntrl-min;

              @include transition(all 0.325s ease-in-out);
            }
          }
        }

        &:not(.burger-menu__icon--active):hover {
          @include responsive-up(tablet-extra-large) {
            background-color: $clr-primary;

            span:nth-child(2) {
              width: 15px;

              @include transition(all 0.325s ease-in-out);
            }
          }
        }
      }
    }

    .logo {
      svg {
        width: 150px;
        height: 27px;

        @include responsive-down(tablet) {
          width: 125px;
          height: 23px;
        }
      }
    }
  }

  &__search {
    width: 450px;

    @include responsive-down(tablet-extra-large) {
      width: 350px;
    }

    @include responsive-down(tablet) {
      width: 100%;
      padding: 15px 40px;
      position: fixed;
      top: 86px;
      left: 0;
      background: $clr-ntrl-min;
      opacity: 1;

      @include transition(opacity 0.325s ease-in-out);
    }

    @include responsive-down(tablet) {
      padding: 5px 15px 15px;
    }

    form {
      position: relative;
      overflow: hidden;

      input {
        width: 100%;
      }

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        position: absolute;
        right: 10px;
        top: 0;

        button {
          background-color: transparent !important;
        }

        svg {
          width: 18px;

            * {
            stroke: $clr-primary;
          }
        }
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 205px;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      margin-right: 15px;
      background-color: $clr-base-background;
      border-radius: 100px;
      position: relative;
      cursor: pointer;

      @include responsive-down(mobile) {
        margin-right: 10px;
      }

      &--search {
        opacity: 0;

        @include transition(opacity 0.325s ease-in-out);


        @include responsive-up(tablet) {
          display: none;
        }
      }

      &--info {
        @include responsive-down(tablet) {
          display: none;
        }
      }

      svg {
        width: 20px;
        height: 20px;

        @include transition (fill 0.325s ease-in-out);
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        @include responsive-up(tablet-extra-large) {
          svg {
            * {
              fill: $clr-primary;

              @include transition (fill 0.325s ease-in-out);
            }
          }
        }
      }

      .quantity {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15px;
        height: 15px;
        background-color: $clr-primary;
        border-radius: 100px;
        font-size: 10px;
        color: $clr-ntrl-min;
        position: absolute;
        top: -5px;
        right: -5px;
      }
    }
  }

  &__resume-nav {
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid $clr-base-ltst;
    border-bottom: 1px solid $clr-base-ltst;
    position: relative;
    z-index: 1;
    background-color: $clr-ntrl-min;
    transform: translateY(0);
    height: auto;

    @include transition(all 0.325s ease-in-out);

    @include responsive-down(tablet) {
      display: none;
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 40px;

      li {
        overflow: hidden;
        position: relative;

        &::before {
          transform: translateX(-100%);

          @include transition (0.325s cubic-bezier(0.785, 0.135, 0.15, 0.86));
        }

        span {
          color: $clr-ntrl-max;
          cursor: pointer;

          @include transition (color 0.325s cubic-bezier(0.785, 0.135, 0.15, 0.86));
        }

        &:hover {
          @include responsive-up(tablet-extra-large) {
            &::before {
              transform: translateX(0);

              @include transition (0.325s cubic-bezier(0.785, 0.135, 0.15, 0.86));
            }

            span {
              color: $clr-primary;

              @include transition (color 0.325s cubic-bezier(0.785, 0.135, 0.15, 0.86));
            }
          }
        }
      }
    }
  }

  &__nav-level {
    width: 100%;
    max-width: 400px;
    height: 100dvh;
    padding: 150px 50px 50px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: $clr-ntrl-min;
    pointer-events: none;
    transform: translateX(-100%);
    transition: transform 0.65s cubic-bezier(0.77, 0.2, 0.05, 1.0);

    @include responsive-down(tablet-extra-large) {
      max-width: 100%;
      padding: 120px 40px 50px;
    }

    @include responsive-down(mobile-extra-large) {
      padding: 120px 20px 50px;
    }

    @include responsive-down(mobile) {
      padding: 120px 15px 50px;
    }

    ul {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 5px;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        border-radius: 6px;
        background-color: $clr-ntrl-min;
        position: relative;
        overflow: hidden;
        cursor: pointer;

        @include transition(background-color 0.325s ease-in-out);

        @include responsive-down(mobile) {
          padding: 15px 0;
        }

        &::before {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background-color: $clr-base-ltst;
          z-index: 1;
          transform: translateX(-100%);

          @include transition(transform 0.325s cubic-bezier(0.785, 0.135, 0.15, 0.86));
        }

        span,
        a {
          position: relative;
          z-index: 2;
          font-size: 16px;

          @include responsive-down(tablet-extra-large) {
            font-size: 16px;
          }
        }

        a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 30px;
          color: $clr-primary;
          background-color: transparent;
          border-radius: 100px;
          position: relative;
          z-index: 2;

          @include transition(background-color 0.325s ease-in-out);
        }

        &:hover {
          @include responsive-up(tablet-extra-large) {
            &::before {
              transform: translateX(0);

              @include transition(transform 0.325s cubic-bezier(0.785, 0.135, 0.15, 0.86));
            }

            .icon {
              background-color: $clr-ntrl-min;

              @include transition(background-color 0.325s ease-in-out);
            }
          }
        }

        &.active {
          @include responsive-up(tablet-extra-large) {
            &::before {
              transform: translateX(0);

              @include transition(transform 0.325s cubic-bezier(0.785, 0.135, 0.15, 0.86));
            }

            .icon {
              background-color: $clr-ntrl-min;

              @include transition(background-color 0.325s ease-in-out);
            }
          }
        }

        &.submenu-simple {
          i {
            display: none;
          }
        }

        svg {
          width: 10px;
          transform: rotate(90deg);

          * {
            fill: $clr-primary;
          }
        }
      }
    }

    &--02,
    &--03 {
      padding-right: 20px;
      transform: translateX(-200%);

      @include responsive-down(tablet-extra-large) {
        max-width: 100%;
        transform: translateX(-100%);
      }

      .go-back-link-menu {
        margin-bottom: 15px;
        cursor: pointer;

        svg {
          transform: rotate(-90deg);
          margin: 0 5px 0 0;
        }

        @include responsive-up(tablet-extra-large) {
          display: none;
        }
      }

      .nav-item-link {
        margin-bottom: 30px;

        @include responsive-down(tablet-extra-large) {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: calc(100% + 35px);
          margin: 0 -20px 30px -15px;
          background-color: $clr-base-background;
          padding: 15px;
        }

        .linktitle-mobile {
          width: calc(100% - 87px);
          font-size: 22px;

          @include responsive-up(tablet-extra-large) {
            display: none;
          }
        }

        a {
          color: $clr-primary;
          font-size: 13px;
        }
      }

      ul {
        max-height: 70dvh;
        overflow-y: auto;
        padding-right: 40px;

        @include responsive-down(tablet-extra-large) {
          max-height: 60dvh;
          padding-right: 0;
          padding: 0 0 80px;
        }

        li {
          padding: 10px 15px;
          font-size: 13px;
          border-radius: 3px;
          min-height: 35px;

          .icon {
            width: auto;
            height: auto;
          }

          &:hover {
            @include responsive-up(tablet-extra-large) {
              .icon {
                background-color: transparent;
              }
            }
          }

          &.active {
            .icon {
              background-color: transparent;
            }
          }

          &.submenu-simple {
            .icon {
              display: none;
            }
          }
        }
      }
    }

    &--01 {
      border-right: 1px solid $clr-base-ltst;
      transition: transform 1.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
      z-index: 4;

      @include responsive-up(tablet-extra-large) {
        .menu-contact {
          display: none;
        }
      }

      @include responsive-down(tablet-extra-large) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        ul {
          li {
            span,
            a {
              @include responsive-down(tablet-extra-large) {
                font-size: 20px;
              }
            }

            .icon {
              @include responsive-down(tablet-extra-large) {
                background-color: $clr-base-background;

                svg {
                  fill: $clr-primary;
                }
              }
            }
          }
        }

        .menu-contact {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-bottom: 50px;
        }
      }
    }

    &--02 {
      border-right: 1px solid $clr-base-ltst;
      transition: transform 1s cubic-bezier(0.77, 0.2, 0.05, 1.0);
      z-index: 3;

      @include responsive-down(tablet-extra-large) {
        z-index: 4;
      }
    }

    &--03 {
      transform: translateX(-300%);
      transition: transform 0.85s cubic-bezier(0.77, 0.2, 0.05, 1.0);
      z-index: 2;

      @include responsive-down(tablet-extra-large) {
        transform: translateX(-100%);
        z-index: 4;
      }
    }
  }

  &__shadow {
    width: 100%;
    height: 100dvh;
    background-color: $clr-ntrl-max;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    pointer-events: none;

    @include transition(opacity 1.5s ease-in-out);
  }

  &--fixed {
    padding-bottom: 0;
    box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.05);

    @include transition(all 0.5s ease-in-out);

    .header__top {
      transform: translateY(-50px);
      height: 0;
      padding-top: 0;
      padding-bottom: 0;

      @include transition(all 0.325s ease-in-out);

      .container {
        opacity: 0;
        height: 0;

        @include transition(all 0.175s ease-in-out);
      }
    }

    .header__resume-nav {
      transform: translateY(-50px);
      height: 0;
      padding-top: 0;
      padding-bottom: 0;

      @include transition(all 0.325s ease-in-out);

      ul {
        opacity: 0;
        height: 0;

        @include transition(all 0.175s ease-in-out);
      }
    }

    .header__search {
      @include responsive-down(tablet) {
        top: 60px;
        opacity: 0;
        pointer-events: none;

        @include transition(opacity 0.325s ease-in-out);
      }
    }

    .header__actions {
      .icon {
        &--search {
          opacity: 1;

          @include transition(opacity 0.325s ease-in-out);
        }
      }
    }
  }
}

body.menu-opened {
  overflow: hidden;

  .header {
    @include responsive-down(tablet) {
      padding-bottom: 0;

      .header__search {
        opacity: 0;
        pointer-events: none;
      }
    }

    &__content {
      border-bottom: 1px solid #f5f3f2;
    }

    &__nav-level {
      pointer-events: initial;
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);

      &--01 {
        transform: translateX(0);
      }

      &--02 {
        &.active {
          transform: translateX(100%);

          @include responsive-down(tablet-extra-large) {
            transform: translateX(0);
          }
        }
      }

      &--03 {
        &.active {
          transform: translateX(200%);

          @include responsive-down(tablet-extra-large) {
            transform: translateX(0);
          }
        }
      }
    }

    &__shadow {
      opacity: 0.5;
      pointer-events: initial;

      @include transition(opacity 1.5s ease-in-out);
    }
  }
}

.block-account {
  @include responsive-down(mobile) {
    &::before {
      content: '';
      background-color: rgba(0, 0, 0, 0.4);
      position: absolute;
      top: 0;
      right: 0;
      width: 100vw;
      height: 100vh;
      z-index: 1;
      transform: translateX(-300%);

      @include transition (transform 0.65s ease-in-out);
    }
  }

  &__modal {
    position: fixed;
    top: 89px;
    right: 50px;
    width: 300px;
    padding: 50px 15px 20px;
    background: $clr-base-background;
    border: 1px solid $clr-base-ltst;
    transform: translateX(300%);
    pointer-events: none;
    z-index: 9999;

    @include transition (transform 0.65s ease-in-out);

    @include responsive-down(tablet) {
      top: 91px;
      right: 20px;
    }

    @include responsive-down(mobile) {
      height: 100dvh;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      transform: translateX(-300%);
    }

    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      background-color: $clr-ntrl-min;
      border-radius: 100px;
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 4;

      @include transition(all 0.3s ease-in);

      span {
        font-size: 22px;
      }

      @include responsive-down(tablet-extra-large) {
        svg {
          width: 20px;
          fill: $clr-base;
        }
      }

      &:hover {
        background: $clr-base-background;

        span {
          color: $clr-primary;
        }
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0 0 20px;
  }

  &__footer {
    padding: 20px 10px 10px;
    border-top: 1px solid $clr-base-ltr;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;

    @include responsive-down(mobile) {
      margin: auto 0 0;
    }
  }

  .link {
    overflow: hidden;
  }

  &.opened {
    .block-account__modal {
      transform: translateX(0);
      pointer-events: inherit;

      @include transition (transform 0.65s ease-in-out);
    }

    &::before {
      transform: translateX(0);

      @include transition (transform 0.65s ease-in-out);
    }
  }

  &--login {
    .block-account {
      &__modal {
        padding: 30px 15px 20px;
      }

      &__header {
        p {
          margin: 0;
        }
      }

      &__body {
        align-items: flex-start;
        padding: 20px 0 30px;
      }

      &__footer {
        .btn {
          width: 100%;
        }
      }
    }
  }
}

.header--fixed {
  .block-account {
    &__modal {
      top: 62px;

      @include responsive-down(tablet) {
        top: 60px;
      }

      @include responsive-down(mobile) {
        top: 0;
      }
    }
  }
}

