/* components::card-category
--------------------------------------------------------------------------------------------------------------------- */
.card-category {
  display: block;
  height: 200px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;

  @include responsive-up(desktop-extra-large) {
    height: 400px;
  }

  @include responsive-up(desktop-large) {
    height: 300px;
  }

  @include responsive-down(tablet) {
    height: 125px;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &::before {
      content: '';
      width: 100%;
      height: 130%;
      position: absolute;
      top: 0;
      left: 0;
      background: transparent linear-gradient(1deg, #000000db 0%, #00000000 100%) 0% 0% no-repeat padding-box;
      mix-blend-mode: multiply;
      opacity: 0.76;

      @include transition (all 0.3s ease);

      @include responsive-down(tablet) {
        background: #2F3634 0% 0% no-repeat padding-box;
        mix-blend-mode: multiply;
        border-radius: 8px;
        opacity: 0.73;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    max-width: 400px;

    @include responsive-down(tablet) {
      justify-content: space-between;
    }
  }

  .title {
    font-size: 1rem;
    color: $clr-ntrl-min;
    line-height: 1.2;
    margin: 0;
  }

  .subtitle {
    font-size: 13px;
    color: $clr-ntrl-min;
    font-weight: 300;

    @include responsive-down(tablet) {
      display: none;
    }
  }

  &__up {
    @include responsive-up(tablet) {
      margin: 0 auto;
      opacity: 0;
      max-height: 0;

      @include transition (all 0.3s ease);
    }
  }

  &:hover {
    @include responsive-up(tablet-extra-large) {
      .card-category__up {
        transition: all 0.3s ease;
        opacity: 1;
        max-height: 150px;
      }

      .card-category__img {
        &::before {
          background: transparent linear-gradient(0deg, #000000AF 0%, #0000008E 100%) 0% 0% no-repeat padding-box;
          mix-blend-mode: multiply;
          opacity: 0.97;

          @include transition (all 0.3s ease);
        }
      }
    }
  }

  @include responsive-down(tablet-extra-large) {
    .itemprocess__description {
      opacity: 1;
      max-height: 100%;

      @include transition (opacity 0.3s ease);
    }

    .itemprocess__img {
      &::before {
        height: 100%;

        @include transition (height 0.3s ease);
      }
    }
  }
}
