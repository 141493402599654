/* components::full-slider
--------------------------------------------------------------------------------------------------------------------- */
.full-slider {
  position: relative;

  .tns-nav {
    position: absolute;
    bottom: 100px;
    left: 100px;
    z-index: 8;

    @include responsive-down(tablet-extra-large) {
      bottom: 80px;
      left: 40px;
    }

    @include responsive-down(mobile-extra-large) {
      left: 20px;
    }

    @include responsive-down(mobile) {
      left: 15px;
    }


    button {
      width: 10px;
      height: 10px;
      margin-right: 15px;
      border-radius: 100px;
      padding: 0;
      position: relative;
      overflow: hidden;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $clr-ntrl-min;
        opacity: 0.35;
        z-index: 2;
      }

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $clr-ntrl-min;
        z-index: 3;
        transform: translateX(-100%);
        opacity: 0;

        @include transition (opacity 0.125s ease-in-out);
      }

      &.tns-nav-active {
        width: 80px;
        cursor: not-allowed;

        &::after {
          opacity: 1;
          transform: translateX(0);

          @include transition (transform 6s ease-in-out);
        }

        &:hover {
          background-color: transparent;
        }
      }
    }
  }

  &__content {
    height: 520px;
    overflow: hidden;

    @include responsive-down(mobile-large) {
      height: 420px;
    }
  }

  .tns-visually-hidden {
    display: none;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 520px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    white-space: normal;

    @include responsive-down(mobile-large) {
      height: 420px;
    }

    .image {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .content {
      max-width: 750px;
      width: 100%;
      padding: 100px 100px 0;
      position: relative;
      z-index: 2;

      @include responsive-down(tablet) {
        padding: 30px 15px 0;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      .title {
        margin: 0 0 20px;
        font-size: 40px;
        line-height: 47px;
        color: $clr-ntrl-min;

        @include responsive-down(tablet) {
          padding: 30px 0 0;
          font-size: 24px;
          line-height: 31px;
        }
      }

      p {
        color: $clr-ntrl-min;
      }

      .btn,
      button {
        margin-top: 20px;
      }
    }
  }
}
