/* common::classes
--------------------------------------------------------------------------------------------------------------------- */
* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  accent-color: $clr-primary;

  @include responsive-down(pc) {
    text-rendering: auto;
  }

  &:focus,
  &:focus-visible {
    outline: none;
  }
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
}

iframe {
  border: 0 solid transparent;
  width: 100%;
  height: 100%;
}

select,
textarea,
input[type=text] {
  // @include apearance(none);
  border-radius: 5px;
  border: none;
  box-shadow: none;
}

textarea {
  width: 100%;
  min-height: 150px;
  resize: vertical;
}

/* GENERALES */
body {
  background: $clr-ntrl-min;
  margin: 0;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  font-family: $ff-base;
  overflow-x: hidden;

  @include responsive-down(tablet) {
    height: 100%;
    min-height: 100vh;
  }

  &.filters-opened {
    overflow-y: hidden;
  }
}

main {
  margin-top: 124px;
  overflow-x: hidden;

  @include responsive-down(tablet-extra-large) {
    margin-top: 142px;
  }
}

/* ENCABEZADOS */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
  padding: 0;
  text-align: left;
  font-family: $ff-base-extra;
  font-weight: 500;
}

h1,
.h1 {
  font-size: 48px;
  line-height: 1;
  font-weight: 400;
}

h2,
.h2 {
  font-size: 38px;
  line-height: 1.3;
  font-weight: 400;

  @include responsive-down(tablet-small) {
    font-size: 30px;
  }
}

h3,
.h3 {
  font-size: 30px;
  line-height: 1;
  font-weight: 300;
}

h4,
.h4 {
  font-size: 24px;
  line-height: 1.8;
  font-weight: 400;
}

h5,
.h5 {
  font-size: 20px;
  line-height: 1.8;
  font-weight: 400;
}

a {
  text-decoration: none;
  color: $clr-ntrl-max;
  font-family: $ff-base-extra;

  @include transition (all 0.2s ease 0s);

  &:hover {
    @include responsive-up(tablet) {
      color: $clr-primary;

      @include transition (all 0.2s ease 0s);
    }
  }

  &:focus {
    outline: none;
  }
}

p {
  font-size: 1rem;
  line-height: 1.4;
  color: $clr-base;

  strong {
    color: $clr-ntrl-max;
    font-weight: 600;
  }
}

span {
  font-family: $ff-base-extra;
}

button {
  border: none;
  color: $clr-primary;
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  background-color: transparent;

  @include transition (all 0.2s ease 0s);

  &:hover {
    @include responsive-up(tablet) {
      background-color: $clr-primary;
      color: $clr-ntrl-min;

      @include transition (all 0.2s ease 0s);
    }
  }
}

table {
  width: 100%;
  font-family: $ff-base;
  margin: 50px 0 10px;
  font-weight: 200;
  border-collapse: collapse;

  thead {
    tr {
      th {
        font-size: 18px;
      }

      &:nth-child(2) {
        th {
          font-size: 14px;
        }
      }
    }
  }

  th,
  td {
    font-weight: 200;
    border: 1px solid $clr-base-ltr;
    background: $clr-ntrl-min;
    text-align: center;
    padding: 15px 0;
    font-size: 14px;
  }

  tbody {
    td {
      color: $clr-base-ltr;
    }
  }

  th {
    background-color: $clr-base-background;
  }
}

ul {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

a {
  color: $clr-base;
  text-decoration: none;
  font-size: 14px;

  @include transition(color 0.325s ease-in-out);

  &:hover {
    @include responsive-up(tablet-extra-large) {
      color: $clr-primary;

      @include transition(color 0.325s ease-in-out);
    }
  }
}

input,
select {
  padding: 10px;
  background-color: $clr-base-background;
  color: $clr-base;
  font-size: 14px;
  border-radius: 4px;
  border: none;
}
