/* variables::responsive::up
--------------------------------------------------------------------------------------------------------------------- */

@mixin responsive-up($breakpoint) {
  @if $breakpoint == desktop-ultra-large {
    @media only screen and (min-width: $desktop-ultra-large) {
      @content;
    }
  }
  @if $breakpoint == desktop-mega-large {
    @media only screen and (min-width: $desktop-mega-large) {
      @content;
    }
  }

  @if $breakpoint == desktop-extra-large {
    @media only screen and (min-width: $desktop-extra-large) {
      @content;
    }
  }

  @else if $breakpoint == desktop-large {
    @media only screen and (min-width: $desktop-large) {
      @content;
    }
  }

  @else if $breakpoint == desktop {
    @media only screen and (min-width: $desktop) {
      @content;
    }
  }

  @else if $breakpoint == desktop-small {
    @media only screen and (min-width: $desktop-small) {
      @content;
    }
  }

  @else if $breakpoint == desktop-extra-small {
    @media only screen and (min-width: $desktop-extra-small) {
      @content;
    }
  }

  @else if $breakpoint == tablet-extra-large {
    @media only screen and (min-width: $tablet-extra-large) {
      @content;
    }
  }

  @else if $breakpoint == tablet-large {
    @media only screen and (min-width: $tablet-large) {
      @content;
    }
  }

  @else if $breakpoint == tablet {
    @media only screen and (min-width: $tablet) {
      @content;
    }
  }

  @else if $breakpoint == tablet-small {
    @media only screen and (min-width: $tablet-small) {
      @content;
    }
  }

  @else if $breakpoint == tablet-extra-small {
    @media only screen and (min-width: $tablet-extra-small) {
      @content;
    }
  }

  @else if $breakpoint == mobile-extra-large {
    @media only screen and (min-width: $mobile-extra-large) {
      @content;
    }
  }

  @else if $breakpoint == mobile-large {
    @media only screen and (min-width: $mobile-large) {
      @content;
    }
  }

  @else if $breakpoint == mobile {
    @media only screen and (min-width: $mobile) {
      @content;
    }
  }

  @else if $breakpoint == mobile-small {
    @media only screen and (min-width: $mobile-small) {
      @content;
    }
  }

  @else if $breakpoint == mobile-extra-small {
    @media only screen and (min-width: $mobile-extra-small) {
      @content;
    }
  }

  @else {
    // nothing
  }
}
