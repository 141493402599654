/* components::above-cards
--------------------------------------------------------------------------------------------------------------------- */
.above-cards {
  margin: -35px 0 100px;
  z-index: 4;
  position: relative;

  &__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 20px;

    @include responsive-down(tablet) {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 20px;
    }

    @include responsive-down(mobile) {
      grid-template-columns: 1fr;
      grid-column-gap: 0;
    }
  }
}
