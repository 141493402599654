/* components::blockcart-modal
--------------------------------------------------------------------------------------------------------------------- */
.blockcart-modal {
  position: relative;

  &__body {
    position: fixed;
    top: 89px;
    right: 50px;
    width: 300px;
    padding: 15px;
    background: $clr-base-background;
    border: 1px solid $clr-base-ltst;
    transform: translateX(300%);
    pointer-events: none;

    @include transition (transform 0.65s ease-in-out);

    @include responsive-down(tablet) {
      top: 91px;
      right: 20px;
    }

    @include responsive-down(mobile) {
      right: 15px;
    }

    & > ul {
      max-height: 200px;
      padding-right: 15px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
        border-radius: 20px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 20px;
      }

      &::-webkit-scrollbar-thumb {
        background: $clr-base;
        border-radius: 20px;
      }

      li {
        margin-bottom: 15px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .product-content {
      display: grid;
      grid-template-columns: 60px 1fr 20px;
      grid-column-gap: 10px;

      &__img {
        width: 60px;
        height: 60px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &__info {
        p {
          margin: 0;
        }

        .product-name {
          font-size: 12px;
          line-height: 14px;
          color: $clr-base;
          margin-bottom: 5px;
        }

        .product-quantity {
          font-size: 12px;
          line-height: 12px;
          color: $clr-base-ltr;
          margin-bottom: 5px;
        }
      }

      &__delete {
        display: flex;
        align-items: flex-end;
      }
    }
  }

  &__cart-subtotals {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid $clr-base-ltr;

    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: $clr-base;

      &:first-of-type {
        margin-bottom: 5px;
      }
    }
  }

  &__cart-total {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 15px;
    font-size: 14px;
    color: $clr-base;

    .label {
      margin: 0 auto 0 0;
    }

    &:nth-child(3) {
      margin-top: 5px;

      .value {
        color: $clr-base-lt;
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    margin-top: 30px;

    .btn {
      width: 100%;
      padding: 15px 25px;

      &__text {
        font-size: 14px;
      }
    }
  }

  &.opened {
    .blockcart-modal__body {
      transform: translateX(0);
      pointer-events: inherit;

      @include transition (transform 0.65s ease-in-out);
    }
  }
}

.header--fixed {
  .blockcart-modal {
    &__body {
      top: 62px;

      @include responsive-down(tablet) {
        top: 60px;
      }
    }
  }
}

.page-cart {
  .blockcart-modal {
    display: none;
  }

  .header__actions .icon:nth-of-type(2) {
    margin-right: 0;
  }
}
