/* common::variables
--------------------------------------------------------------------------------------------------------------------- */

// common::variables::breakpoints
$desktop-ultra-large: 3800px;
$desktop-mega-large: 3200px;
$desktop-extra-large: 2560px;
$desktop-large: 1919px;
$desktop: 1600px;
$desktop-small: 1440px;
$desktop-extra-small: 1366px;
$tablet-extra-large: 1280px;
$tablet-large: 1080px;
$tablet: 991px;
$tablet-small: 860px;
$tablet-extra-small: 768px;
$mobile-extra-large: 720px;
$mobile-large: 640px;
$mobile: 540px;
$mobile-small: 480px;
$mobile-extra-small: 375px;

// common::variables::typography::font-family
$ff-base: 'Nunito', sans-serif;
$ff-base-extra: "Onest", sans-serif;
$ff-extra: 'IBM Plex Mono', monospace;
$ff-icon: 'icomoon', sans-serif;

// common::variables::colors::brand
$clr-primary: #ff8100;
$clr-primary-hover: #e67300;

// common::variables::colors::base
$clr-base: #2f3634;
$clr-base-lt: #7a807e;
$clr-base-ltr: #bfbfbf;
$clr-base-ltst: #f5f3f2;
$clr-base-background: #f7f7f7;
$clr-line: #ececec;
// common::variables::colors::neutrals
$clr-ntrl-min: #fff;
$clr-ntrl-max: #000;

// common::variables::colors::utility
$clr-success: #8ac148;
$clr-success-dk: #599014;
$clr-info: #00a9f4;
$clr-info-dk: #007ac1;
$clr-warning: #ff9800;
$clr-warning-dk: #c66900;
$clr-danger: #e22121;
$clr-danger-dk: #ab000e;

// common::variables::colors::tags
$clr-tag-bestseller: #956d37;
$clr-tag-bestseller-lt: #ffd9a4;
$clr-tag-discount: #843635;
$clr-tag-discount-lt: #e5a8a6;
$clr-tag-stock: #4c7674;
$clr-tag-stock-lt: #a6e5e3;
$clr-tag-new: #6b805d;
$clr-tag-new-lt: #c0e5a6;
