/* common::extends
--------------------------------------------------------------------------------------------------------------------- */

//Custom Scrollbar
.scrollbar-custom {
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: $clr-base-ltst;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $clr-primary;
    border-radius: 10px;
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $clr-primary;
  padding: 15px 45px;
  border-radius: 50px;
  border: none;
  font-size: 1em;
  line-height: 1;
  font-weight: 300;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -50%;
    width: 200%;
    height: 600px;
    border-radius: 600px;
    transform: translateY(150px);
    z-index: 1;
    background-color: $clr-primary-hover;

    @include transition (all 0.325s cubic-bezier(0.7, 0, 0.2, 1));
  }

  &__text {
    color: $clr-ntrl-min;
    position: relative;
    z-index: 2;
  }

  svg {
    width: 10px;
    margin-left: 10px;
    fill: $clr-ntrl-min;
    position: relative;
    z-index: 2;
  }

  &:hover {
    @include responsive-up(tablet-extra-large) {
      &::before {
        transform: translateY(0);
        height: 100%;
        animation: btn-topBefore 0.5s linear;
      }
    }
  }

  &--white {
    background-color: $clr-ntrl-min;

    &::before {
      background-color: $clr-base-background;
    }

    .btn__text {
      color: $clr-primary;
    }
  }

  &--line-white {
    padding: 15px 25px;
    background-color: transparent;
    border: 1px solid $clr-ntrl-min;

    &::before {
      background-color: $clr-ntrl-min;
    }

    &:hover {
      @include responsive-up(tablet-extra-large) {
        .btn__text {
          color: $clr-base;

          @include transition(color 0.325s ease-in-out);
        }

        svg {
          fill: $clr-base;

          @include transition(fill 0.325s ease-in-out);
        }
      }
    }
  }

  &--beige {
    margin-top: 10px;
    background-color: $clr-base-background;

    .btn__text,
    span {
      color: $clr-base;
      z-index: 3;
    }

    &::before {
      background-color: $clr-base-ltr;
    }
  }
}

.link {
  font-size: 14px;
  color: $clr-primary;
  position: relative;
  display: inline-flex;
  align-items: center;

  &::before {
    content: "";
    height: 1px;
    width: 100%;
    background-color: $clr-primary;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &__text {
    color: $clr-primary;
    font-size: 14px;

    @include transition(all 0.4s ease-in-out);
  }

  svg {
    transform: rotate(90deg);
    width: 10px;
    margin: 0 0 0 5px;

    * {
      fill: $clr-primary;
    }
  }

  &:hover {
    @include responsive-up(tablet-extra-large) {
      .link__text {
        padding: 0 10px 0 0;

        @include transition(all 0.4s ease-in-out);
      }
    }
  }
}

.btn-icon {
  padding: 10px;
  border-radius: 100px;
  background-color: $clr-base-background;
  display: inline-flex;
  align-items: center;

  svg {
    width: 20px;
  }

  i {
    font-size: 20px;
    color: $clr-primary;

    @include transition(all 0.3s ease-in);
  }

  &:hover {
    @include responsive-up(tablet-extra-large) {
      i {
        color: $clr-primary;

        @include transition(all 0.3s ease-in);
      }
    }
  }
}

@keyframes btn-topBefore {
  0% {
      transform: translateY(150px);
      height: 300px
  }

  50% {
      transform: translateY(0);
      height: 300px
  }

  to {
      height: 100%
  }
}

.checkbox-cnt {
  position: relative;

  label {
    display: block;
    padding-left: 30px;
    text-align: left;
    font-size: 12px;
    line-height: 18px;
    color: $clr-base-lt;
    position: relative;
    z-index: 2;
    cursor: pointer;

    em {
      font-style: inherit;
      font-size: 10px;
    }

    a {
      font-size: 12px;
      line-height: 18px;

      * {
        font-size: 12px;
        line-height: 18px;
      }

      &:hover {
        @include responsive-up(tablet-extra-large) {
          text-decoration: underline;
        }
      }
    }
  }

  .checkbox {
    --border-default: #bfbfbf;
    --border-hover: #7a807e;
    --active: #ff8100;
    --active-tick: #ffffff;
    display: block;
    width: 18px;
    height: 18px;
    position: absolute;
    z-index: 1;
    -webkit-tap-highlight-color: transparent;

    svg {
        display: block;
        position: absolute;
    }

    input {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 16px;
        height: 16px;
        padding: 0;
        border-radius: 5px;
        box-shadow: inset 0 0 0 1px var(--border, var(--border-default));
        background: var(--background, transparent);
        transition: background 0.25s linear, box-shadow 0.25s linear;

        & + svg {
            width: 21px;
            height: 18px;
            left: 0;
            top: 0;
            color: var(--active);

            .tick {
                stroke-dasharray: 20;
                stroke-dashoffset: var(--stroke-dashoffset, 20);
                transition: stroke-dashoffset 0.2s;

                &.mask {
                    stroke: var(--active-tick);
                }
            }

            & + svg {
                width: 11px;
                height: 11px;
                fill: none;
                stroke: var(--active);
                stroke-width: 1.25;
                stroke-linecap: round;
                top: -6px;
                right: -10px;
                stroke-dasharray: 4.5px;
                stroke-dashoffset: 13.5px;
                pointer-events: none;
                animation: var(--animation, none) 0.2s ease 0.175s;
            }
        }

        &:checked {
            --background: var(--active);
            --border: var(--active);

            & + svg {
                --stroke-dashoffset: 0;

                & + svg {
                    --animation: check;
                }
            }
        }
    }

    &:hover {
      @include responsive-up(tablet-extra-large) {
        input {
          &:not(:checked) {
              --border: var(--border-hover);
          }
        }
      }
    }
  }

  @keyframes check {
    100% {
        stroke-dashoffset: 4.5px;
    }
  }
}

.custom-radio {
  display: flex;
  align-items: center;
  cursor: pointer;

  input + span {
    display: inline-flex;
    margin-left: 10px;
  }
}

input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: $clr-ntrl-min;
  margin: 0;
  width: 24px;
  height: 24px;
  border: 1px solid $clr-base-ltr;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: inline-grid;
  place-content: center;
  cursor: pointer;

  &::before {
    content: "";
    width: 14px;
    height: 14px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em $clr-primary;
  }

  &:checked::before {
    transform: scale(1);
  }
}

.accordion {
  position: relative;
  gap: 40px;

  @include responsive-up(tablet) {
    border-top: none;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }

  @include responsive-up(tablet) {
    gap: 0 !important;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 20px;
    color: $clr-base-lt;
    margin: 0 0 35px;
    font-family: $ff-base-extra;
    position: relative;

    @include responsive-down(tablet) {
      padding: 0;
      margin: 0;
      color: $clr-base-ltr;
    }

    .arrow {
      width: 16px;
      height: 16px;
      position: absolute;
      right: 4px;
      top: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: rotate(180deg);
      display: none;

      @include responsive-down (tablet) {
        display: inline-block;
      }

      svg {
        fill: $clr-ntrl-min;
        width: 100%;
        height: 100%;
      }

      span {
        top: 8px;
        position: absolute;
        width: 10px;
        height: 2px;
        border-radius: 2px;
        background-color: $clr-base;
        display: inline-block;
        transition: all 0.2s ease;

        &:first-child {
          left: 6px;
          transform: rotate(45deg);
        }

        &:last-child {
          transform: rotate(-45deg);
        }
      }
    }
  }

  &__item {
    margin: 25px 0;
    padding: 0 0 50px;
    width: calc(100% / 3 - 60px);

    @include responsive-up(tablet) {
      order: 2;
      margin: 0;
    }

    @include responsive-down(tablet) {
      margin: 0 0 10px;
      width: 100%;
      background: #363d3a;
      border-radius: 8px;
      padding: 10px;
    }

    &:first-child {
      margin-top: 0;

      .accordion__body {
        display: flex;
        flex-direction: column;

        & > * {
          margin: 9px 0;

          @include responsive-down(tablet) {
            margin: 12.5px 0;
          }
        }

        li {
          order: 2;

          &:last-child {
            order: 1;
            margin-bottom: 9px;
          }
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.active {
      margin: 0;

      @include responsive-down(tablet) {
        margin: 0 0 10px;
      }

      .arrow {
        transform: rotate(0deg);
      }

      .accordion__body {
        height: auto;
        opacity: 1;
        visibility: visible;
        overflow: visible;
        transition: 0.6s;
        margin: 30px 0 3px;
        transition-duration: 0.6s;
      }
    }

    &--big {
      .accordion__header {
        font-size: 15px;

        @include responsive-down(tablet) {
          font-size: 18px;
        }
      }

      .accordion__body {
        .linkmenu__text {
          font-size: 30px;
          font-weight: 500;

          @include responsive-down(tablet) {
            font-size: 27px;
          }
        }
      }
    }

    &--open {
      .accordion__body {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        gap: 18px;
      }

      .img-pay {
        width: calc(100% / 2 - 20px);
        border-radius: 5px;
        background-color: $clr-ntrl-min;
        max-width: 58px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 5px;

        img,
        svg {
          width: 100%;
          height: 100%;
          object-fit: contain;
          max-height: 25px;
        }
      }

      @include responsive-down(tablet) {
        background: none;
        margin: 26px 0 0;

        &:first-child {
          margin: 26px 0 0;
        }

        .accordion__header {
          color: $clr-base-lt;
          cursor: none;
        }

        .accordion__body {
          opacity: 1;
          visibility: visible;
          height: 100%;
          transform: none;
          overflow: visible;
          padding: 20px 0;
        }
      }
    }
  }

  &__body {
    padding: 0;
    list-style: none;
    margin: 30px 0 3px;

    @include responsive-down(tablet) {
      // display: none;
      width: 100%;
      overflow: hidden;
      height: 1px;
      opacity: 0;
      visibility: hidden;
      transition: 0.6s;
      transform: translateX(10px);
      margin: 0;
    }

    & > * {
      margin: 18px 0;

      @include responsive-down(tablet) {
        margin: 25px 0;
      }
    }

    * {
      color: $clr-ntrl-min;
      font-size: 13px;
      font-weight: 400;
      padding: 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      strong {
        color: $clr-ntrl-min;
        font-weight: 600;
      }

      img.icon {
        width: 12px;
        margin: 0 0 0 5px;
      }
    }

    & > div {
      strong + span {
        font-weight: 600;
      }
    }

    .linkmenu__text {
      @include responsive-down(tablet) {
        font-size: 14px;
      }
    }
  }
}

.link-contact-footer {
  padding: 12px 30px 12px 20px;
  background-color: $clr-base;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;

  @include transition(all 0.3s ease-in-out);

  svg {
    margin: 0 10px 0 0;
    display: inline-block;
    fill: $clr-primary;
    width: 22px;
    height: auto;
  }

  &__text {
    color: $clr-ntrl-min;
    font-size: 14px;
    position: relative;

    &::before {
      content: "";
      background-color: $clr-ntrl-min;
      border-radius: 50px;
      position: absolute;
      height: 1px;
      bottom: -1px;
      z-index: 1;
      transform-origin: left;
      width: 100%;
      transform: scaleX(0);
      transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }

  &:hover {
    @include responsive-up(tablet) {
      background-color: #414b48;

      @include transition(all 0.3s ease-in-out);

      .link-contact-footer__text {
        &::before {
          transform: scaleX(1);
          transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);
        }
      }
    }
  }
}

.linkmenu {
  border-radius: 50px;

  @include transition(all 0.3s ease-in-out);

  &__text {
    color: $clr-ntrl-min;
    font-size: 14px;
    position: relative;

    &::before {
      content: "";
      background-color: $clr-ntrl-min;
      border-radius: 50px;
      position: absolute;
      height: 1px;
      bottom: -1px;
      z-index: 1;
      transform-origin: left;
      width: 100%;
      transform: scaleX(0);
      transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }

  &:hover {
    @include responsive-up(tablet) {

      @include transition(all 0.3s ease-in-out);

      .linkmenu__text {
        &::before {
          transform: scaleX(1);
          transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);
        }
      }
    }
  }
}

.products article .wishlist-button-add {
  z-index: 3 !important;
}

.product {
  .wishlist-button-add {
    width: 38px !important;
    height: 38px !important;
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: $clr-ntrl-min !important;

    &:hover {
      @include responsive-up(tablet-extra-large) {
        background-color: $clr-base-ltst !important;
      }
    }
  }
}

.wishlist-button-add {
  width: 38px !important;
  height: 38px !important;
  margin-left: 0;
  box-shadow: none !important;
  background-color: $clr-base-background !important;
  opacity: 1;
  z-index: 3;

  i {
    color: transparent !important;
    width: 100%;
    height: 12px;

    &:before {
      content: url("data:image/svg+xml,%3Csvg width='20px' height='20px' viewBox='0 0 24 24' fill='gray' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.96173 18.9109L9.42605 18.3219L8.96173 18.9109ZM12 5.50063L11.4596 6.02073C11.601 6.16763 11.7961 6.25063 12 6.25063C12.2039 6.25063 12.399 6.16763 12.5404 6.02073L12 5.50063ZM15.0383 18.9109L15.5026 19.4999L15.0383 18.9109ZM9.42605 18.3219C7.91039 17.1271 6.25307 15.9603 4.93829 14.4798C3.64922 13.0282 2.75 11.3345 2.75 9.1371H1.25C1.25 11.8026 2.3605 13.8361 3.81672 15.4758C5.24723 17.0866 7.07077 18.3752 8.49742 19.4999L9.42605 18.3219ZM2.75 9.1371C2.75 6.98623 3.96537 5.18252 5.62436 4.42419C7.23607 3.68748 9.40166 3.88258 11.4596 6.02073L12.5404 4.98053C10.0985 2.44352 7.26409 2.02539 5.00076 3.05996C2.78471 4.07292 1.25 6.42503 1.25 9.1371H2.75ZM8.49742 19.4999C9.00965 19.9037 9.55954 20.3343 10.1168 20.6599C10.6739 20.9854 11.3096 21.25 12 21.25V19.75C11.6904 19.75 11.3261 19.6293 10.8736 19.3648C10.4213 19.1005 9.95208 18.7366 9.42605 18.3219L8.49742 19.4999ZM15.5026 19.4999C16.9292 18.3752 18.7528 17.0866 20.1833 15.4758C21.6395 13.8361 22.75 11.8026 22.75 9.1371H21.25C21.25 11.3345 20.3508 13.0282 19.0617 14.4798C17.7469 15.9603 16.0896 17.1271 14.574 18.3219L15.5026 19.4999ZM22.75 9.1371C22.75 6.42503 21.2153 4.07292 18.9992 3.05996C16.7359 2.02539 13.9015 2.44352 11.4596 4.98053L12.5404 6.02073C14.5983 3.88258 16.7639 3.68748 18.3756 4.42419C20.0346 5.18252 21.25 6.98623 21.25 9.1371H22.75ZM14.574 18.3219C14.0479 18.7366 13.5787 19.1005 13.1264 19.3648C12.6739 19.6293 12.3096 19.75 12 19.75V21.25C12.6904 21.25 13.3261 20.9854 13.8832 20.6599C14.4405 20.3343 14.9903 19.9037 15.5026 19.4999L14.574 18.3219Z' fill='%gray'/%3E%3C/svg%3E");
      height: 12px;
      opacity: 0.5;
    }

    &.active {
      &:before {
        opacity: 1;
        content: url("data:image/svg+xml,%3Csvg width='20px' height='20px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 9.1371C2 14 6.01943 16.5914 8.96173 18.9109C10 19.7294 11 20.5 12 20.5C13 20.5 14 19.7294 15.0383 18.9109C17.9806 16.5914 22 14 22 9.1371C22 4.27416 16.4998 0.825464 12 5.50063C7.50016 0.825464 2 4.27416 2 9.1371Z' fill='%23ff8100'/%3E%3C/svg%3E");
      }
    }
  }

  &:hover {
    @include responsive-up(tablet-extra-large) {
      background-color: $clr-base-ltst;
    }
  }
}

.wishlist-toast {
  top: 100px !important;

  &.success {
    background-color: $clr-base-background !important;
    border-color: $clr-base-background !important;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .wishlist-toast-text {
    margin: 0;
    color: $clr-base !important;
  }
}

#notifications {
  .notifications-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
  }
}

.alert {
  display: inline-block;
  padding: 5px 20px;
  border-radius: 4px;
  margin: 30px 15px;
  font-size: 14px;
  line-height: 16px;
  background-color: $clr-base-background;
  color: $clr-base-lt;

  &.alert-danger {
    background-color: $clr-tag-discount-lt;
    color: $clr-tag-discount;
  }

  &.alert-success {
    background-color: $clr-tag-new-lt;
    color: $clr-tag-new;
  }
}

/* CMS Pages */
.cms-id-19 {
  .page-header {
    display: none;

    & + .container {
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

/* Questions and answers */
.question {
  display: flex;
  align-items: center;
  padding: 10px 0;
  font-size: 18px;
  position: relative;
  cursor: pointer;

  @include transition(color 0.325s ease-in-out);

  &:first-of-type {
    border-top: none;
  }

  &__title {
    font-size: 20px;
    line-height: 25px;
    color: $clr-base;
    margin: 0;
  }

  &__icon {
    position: absolute;
    top: 8px;
    right: 0;
    width: 10px;
    height: 10px;

    svg {
      transform: rotate(180deg);

      @include transition(all 0.5s ease);
    }
  }

  &--open {
    .question__icon {
      svg {
        transform: rotate(0);

        @include transition(all 0.5s ease);
      }
    }
  }
}

.answer {
  overflow: hidden;
  height: 0;

  &__text {
    padding: 20px 0;

    @include responsive-down(tablet) {
      max-height: 200px;
      overflow-y: auto;
      margin: 20px 0;
      padding: 0;
      padding-right: 20px;
    }

    @include responsive-down(mobile-extra-small) {
      max-height: 100px;
      margin: 15px 0;
    }

    ul {
      li {
        margin-bottom: 20px;

        &:last-of-type {
          @include responsive-down(tablet) {
            margin-bottom: 0;
          }
        }

        a {
          display: flex;
          align-items: start;
          justify-content: space-between;
          font-size: 14px;
          line-height: 18px;
          color: $clr-base-lt;

          span {
            &:nth-child(1) {
              margin-right: 15px;
            }
          }

          &:hover {
            @include responsive-up(tablet-extra-large) {
              color: $clr-primary;
            }
          }
        }
      }
    }
  }
}

.block-color {
  padding: 10px !important;
  background-color: $clr-base-background;
  border-radius: 5px;
  flex-wrap: nowrap !important;
  overflow: scroll;
  max-width: 570px;

  @include responsive-down(tablet-small) {
    padding: 5px !important;
  }

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    background: $clr-base-ltst;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $clr-primary;
    border-radius: 10px;
  }

  &__img {
    width: 30px;
    height: 30px;
    background-color: $clr-base-background;
    border-radius: 50px;
    border: 4px solid $clr-ntrl-min;
    position: relative;

    &::before {
      content: "";
      border: 1px solid #707070;
      position: absolute;
      border-radius: 50px;
      top: -4px;
      left: -4px;
      width: 30px;
      height: 30px;
      opacity: 0;
      visibility: hidden;
    }
  }

  &__item {
    white-space: nowrap;
    min-width: 50px;
    border: none !important;

    label {
      gap: 10px !important;
      flex-direction: column;
    }

    input {
      display: none !important;
    }

    span {
      color: $clr-base-lt;
    }

    &--selected {
      span {
        color: $clr-ntrl-max;
      }

      .block-color__img {
        &::before {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

.form-group {
  .password-requirements {
    p {
      font-size: 10px;
      line-height: 12px;
      color: #7a807e;
    }

    i {
      color: transparent;
      width: 7px;
      position: relative;
      display: inline-block;

      &::before {
        content: "•";
        font-size: 14px;
        color: #7a807e;
      }
    }
  }
}

.wishlist {
  &-list {
    &-item {
      position: relative;
      display: flex;
      align-items: center;

      &::before {
        content: "◦";
        font-size: 14px;
        color: $clr-ntrl-max;
        position: absolute;
        left: 0;
      }
    }
  }
}


/* LINK BACK
--------------------------------------------------------------------------------------------------------------------- */
.pswp {
  z-index: 99999999 !important;
}

/* LINK BACK
--------------------------------------------------------------------------------------------------------------------- */
.pswp {
  .pswp__img {
    background-color: $clr-ntrl-min;
    object-fit: cover;
  }

  &__button {
    position: relative;
    display: block;
    width: 50px;
    height: 60px;
    padding: 0;
    margin: 0;
    overflow: hidden;
    cursor: pointer;
    background: none;
    border: 0;
    box-shadow: none;
    opacity: 0.85;
    -webkit-appearance: none;
    -webkit-touch-callout: none;

    &:hover.characteristic,
    &:active,
    &:focus {
      transition: none;
      padding: 0;
      background: none;
      border: 0;
      box-shadow: none;
      opacity: 1;
    }

    &:disabled {
      opacity: 0.3;
      cursor: auto;
    }

    &--arrow--left,
    &--arrow--right {
      position: absolute;
      width: 75px;
      height: 100px;
      top: 50%;
      margin-top: -50px;

      @include responsive-down(mobile-extra-large) {
        top: auto;
        bottom: 0;
      }

      &:hover {
        border: none;
      }

      .pswp__icn {
        top: 50%;
        margin-top: -30px;
        width: 60px;
        height: 60px;
        background: none;
        border-radius: 0;
        fill: $clr-ntrl-min;
      }

      &:before {
        content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNy45NjUgOS40ODMiIGZpbGw9IndoaXRlIiA+CiAgPHBhdGggZD0iTS0xMzY4LjgwNywxNDEuNDY1YS41LjUsMCwwLDEtLjM1NC0uMTQ2LjUuNSwwLDAsMSwwLS43MDdsOC4xMjktOC4xMjktOC4xMjktOC4xMjlhLjUuNSwwLDAsMSwwLS43MDcuNS41LDAsMCwxLC43MDcsMGw4LjQ4Myw4LjQ4M2EuNS41LDAsMCwxLC4xNDYuMzU0LjUuNSwwLDAsMS0uMTQ2LjM1NGwtOC40ODMsOC40ODNBLjUuNSwwLDAsMS0xMzY4LjgwNywxNDEuNDY1WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEyMy41IC0xMzU5LjgyNCkgcm90YXRlKC05MCkiLz4KPC9zdmc+);
        height: auto;
        width: 27px;
        position: relative;
        display: block;
        transform: rotate(270deg);

        @include responsive-down(mobile-extra-large) {
          width: 27px;
        }
      }

      &.icon-arrow-left {
        right: auto;
        left: 0;
        color: $clr-ntrl-min;
        display: flex;
        align-items: center;
        justify-content: center;

        &:before {
          transform: rotate(270deg);
        }
      }

      &.icon-arrow-right {
        right: 0;
        color: $clr-ntrl-min;
        display: flex;
        align-items: center;
        justify-content: center;

        &:before {
          transform: rotate(90deg);
        }

        .pswp__icn {
          transform: scale(-1, 1);
        }
      }
    }

    &--close {
      cursor: pointer;

      &.pswp__button--close {
        margin-right: 6px;
        background-image: none !important;
        color: white;
        order: 5;
        font-size: 40px;

        .pswp__icn {
          fill: $clr-ntrl-min;
        }

        &:hover {
          border: none;
        }
      }
    }

    &--fs,
    &--share,
    &--zoom {
      display: none;
    }

    &.icon-full-screen {
      &::before {
        content: "\e90d";
        font-family: 'icomoon',sans-serif !important;
      }
    }
  }

  &__icn {
    position: absolute;
    top: 14px;
    left: 9px;
    width: 32px;
    height: 32px;
    overflow: hidden;
    pointer-events: none;
  }

  &__counter {
    height: 30px;
    margin: 0 auto 0 0;
    font-size: 14px;
    line-height: 30px;
    color: $clr-ntrl-min;
    text-shadow: 1px 1px 3px var(--pswp-icon-color-secondary);
    opacity: 0.85;
    order: 1;
  }

  &__preloader {
    display: none;
  }

  &__top-bar {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    z-index: 10;
    align-items: center;
    pointer-events: none !important;
    padding: 0 0 0 30px;
  }
}
