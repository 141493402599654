/* components::contact-fixed
--------------------------------------------------------------------------------------------------------------------- */
.contact-fixed {
  width: 65px;
  height: 65px;
  position: fixed;
  right: 20px;
  bottom: 3dvh;
  z-index: 10;
  transition: width 0.65s ease-in-out, height 0.65s ease-in-out;
  -webkit-transition: width 0.65s ease-in-out, height 0.65s ease-in-out;
  -moz-transition: width 0.65s ease-in-out, height 0.65s ease-in-out;

  @include responsive-down(mobile-large) {
    right: 15px;
  }

  &__icon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 10px;
    position: fixed;
    bottom: 3dvh;
    right: 20px;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45px;
      height: 45px;
      padding: 5px;
      border-radius: 100px;
      border: 1px solid $clr-primary;
      background-color: $clr-primary;
      cursor: pointer;

      svg {
        width: 22px;
        fill: $clr-ntrl-min;
      }

      &:hover {
        @include responsive-up(tablet-extra-large) {
          background-color: $clr-ntrl-min;

          @include transition(background-color 0.325s ease-in-out);

          svg {
            fill: $clr-primary;

            @include transition(fill 0.325s ease-in-out);
          }
        }
      }
    }
  }

  &__content {
    width: 215px;
    padding: 15px 10px 10px 15px;
    border-radius: 8px;
    border-bottom-right-radius: 24px;
    background-color: $clr-ntrl-min;
    box-shadow: 1px 10px 10px 1px rgba(0, 0, 0, 0.2);
    transform: translateX(150%) rotate(45deg);
    pointer-events: none;

    @include transition (transform 0.65s ease-in-out);

    .text {
      p {
        margin: 0 0 20px;
        font-size: 13px;
        line-height: 18px;
        color: $clr-base-lt;
      }
    }

    .list {
      li {
        margin-bottom: 15px;

        a {
          display: flex;
          align-items: center;

          svg {
            width: 18px;
            margin-right: 10px;
            fill: $clr-base-ltr;

            * {
              fill: $clr-base-ltr;
            }
          }
        }
      }
    }

    .button {
      margin-top: 20px;

      a {
        justify-content: center;
        width: 100%;
        padding: 8px;
        text-align: center;

        span {
          font-size: 13px;
          line-height: 1;
          text-align: center;
        }
      }
    }

    .close {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 10px;

      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45px;
        height: 45px;
        padding: 5px;
        border-radius: 100px;
        border: 1px solid $clr-primary;
        background-color: $clr-ntrl-min;
        cursor: pointer;

        svg {
          width: 26px;
          fill: $clr-primary;
        }

        &:hover {
          @include responsive-up(tablet-extra-large) {
            background-color: $clr-primary;

            @include transition(background-color 0.325s ease-in-out);

            svg {
              fill: $clr-ntrl-min;

              @include transition(fill 0.325s ease-in-out);
            }
          }
        }
      }
    }
  }

  &.opened {
    width: 215px;
    height: 250px;

    .contact-fixed__content {
      pointer-events: inherit;
      transform: translateX(0) rotate(0);

      @include transition (transform 0.65s ease-in-out);
    }
  }
}

.page-product {
  .contact-fixed {
    display: none;
  }

  .footer {
    padding: 66px 0;
  }
}
