/* common::grid
--------------------------------------------------------------------------------------------------------------------- */
.container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding-left: 50px;
  padding-right: 50px;

  @include responsive-down (tablet-extra-large) {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }

  @include responsive-down (mobile-extra-large) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include responsive-down (mobile) {
    padding-left: 15px;
    padding-right: 15px;
  }

  &--large {
    max-width: 1920px;
  }

  &--medium {
    max-width: 1440px;

    @include responsive-up(desktop) {
      max-width: 1520px;
    }

    @include responsive-up(desktop-large) {
      max-width: 1720px;
    }
  }

  &--medium-small {
    max-width: 1200px;
  }

  &--small {
    max-width: 1024px;
  }
}
