/* common::breadcrumbs
--------------------------------------------------------------------------------------------------------------------- */
.breadcrumbs {
  display: flex;
  align-items: center;
  padding: 20px 0;
  position: relative;
  z-index: 3;
  margin-top: 0;

  li {
    display: flex;
    align-items: center;
    height: 14px;
    padding: 0 10px;
    position: relative;
    overflow: hidden;

    &:nth-child(1) {
      padding-left: 0;
    }

    @include responsive-down(mobile-extra-large) {
      display: inline-flex;
      align-items: center;
      border-bottom: 1px solid $clr-base-ltr;
      padding: 0 0 6px;
      height: 19px;

      &:not(:nth-last-child(2)) {
        display: none;
      }
    }

    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
      height: 100%;
      background-color: $clr-base-ltr;

      @include responsive-down(mobile-extra-large) {
        display: none;
      }
    }

    .arrow {
      transform: rotate(270deg);
      display: none;
      margin-right: 5px;

      @include responsive-down(mobile-extra-large) {
        display: block;
      }

      svg {
        width: 8px;
        height: 8px;
        fill: $clr-ntrl-min;
      }
    }

    a {
      color: $clr-base-ltr;
      font-size: 11px;
      line-height: 14px;

      &:hover {
        @include responsive-up(tablet-extra-large) {
          text-decoration: underline;
        }
      }
    }

    span {
      color: $clr-base-ltr;
      font-size: 11px;
      line-height: 14px;

      @include responsive-down(mobile-extra-large) {
        font-size: 14px;
      }
    }

    &:last-child {
      &::after {
        display: none;
      }

      a,
      span {
        color: $clr-ntrl-min;
      }
    }
  }
}

.page-product {
  .breadcrumbs {
    @include responsive-down(mobile-extra-large) {
      padding: 10px 0;
    }

    li {
      &:last-child {
        a,
        span {
          color: $clr-base;
        }
      }

      @include responsive-down(mobile-extra-large) {
        a,
        span {
          color: $clr-ntrl-max;
        }
      }

      .arrow {
        svg {
          fill: $clr-ntrl-max;
        }
      }
    }
  }
}
